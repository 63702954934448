import React from "react"

var ReactRotatingText = require("react-rotating-text")

const IndexPage = () => (
  <h1 className="marquee">
    <ReactRotatingText
      items={[
        "Interface Designer",
        "Software Developer",
        "Product Designer",
        "Application Creator",
        "Educator",
      ]}
    />
  </h1>
)

export default IndexPage
